// This file should not output any CSS
$font-primary: neue-haas-grotesk-text, Helvetica, Arial, sans-serif;
$font-display: neue-haas-grotesk-display, $font-primary;
$navlinks: home, brands, distributors, 'retailers', customers, about,
  contact, platform, blog;

$o-fSize--12: 12px;
$o-fSize--18: 18px;
$o-fSize--28: 28px;
$o-fSize--36: 36px;

@mixin tvscreen {
  background-image: linear-gradient(
      90deg,
      black 0%,
      black 33%,
      transparent 66%,
      transparent 100%
    ),
    linear-gradient(
      180deg,
      black 0%,
      black 33%,
      transparent 66%,
      transparent 100%
    );
  opacity: 0.5;
  background-size: 2px 100%, 100% 2px;
  background-repeat: repeat-x, repeat-y;
  mix-blend-mode: multiply;
}
