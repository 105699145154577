.site-footer {
  @apply bg-black text-gray-400 text-sm relative z-20;
  @apply px-6 pt-20 pb-12;
}

.footer-branding {
  @apply pb-8 border-b border-gray mb-12;
  @apply flex justify-between items-center;
}

.social-links {
  display: flex;
  @apply items-center content-end;
}
.social-link {
  @apply h-8 w-8 text-[2rem] m-3 block transition-colors;
  &:hover {
    @apply text-red;
  }
  &:last-child {
    margin-right: 0;
  }
}
.social-link-svg {
  @apply h-10 w-10;
}

.nav-footer {
  @apply flex flex-col text-sm;

  h4 {
    @apply text-white font-medium text-base mb-4;
  }
}
.footer-link {
  @apply block text-sm my-2;
}

.copyright {
  @apply text-[10px] text-center tracking-widest uppercase font-medium mt-12;
}
