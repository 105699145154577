.site-header {
  @apply fixed bg-white shadow-md px-8 py-3 z-50 top-0 min-h-[70px] w-full flex items-center;
}

.site-logo {
  width: 80px;

  @apply block text-red;
}

.primary-nav {
  &-container {
    input {
      @apply absolute h-0 w-0 opacity-0;
      @apply md:hidden;

      &:checked ~ .primary-nav {
        transform: translateX(0);
      }
    }
  }

  @apply flex flex-col space-y-6 bg-white fixed top-[70px] bottom-0 left-0 right-0 p-8;
  transform: translateX(-125%);
  @apply transition-all;
  transition-duration: 200ms;
  @screen md {
    @apply flex flex-row space-x-6 p-0 translate-x-0 inset-auto static items-center space-y-0;
  }
}
.mobile-menu-btn {
  @apply w-6 h-6 p-1 text-black flex-shrink-0 flex items-center relative;
  @apply md:hidden;
  &:hover {
    @apply text-red;
  }
  div {
    @apply h-0.5 w-full;
    background-color: currentColor;
    $spacing: 5px;
    box-shadow: 0 ($spacing * -1) 0 currentColor, 0 $spacing 0 currentColor;
    transition-property: background, transform, box-shadow;
    transition-timing-function: ease-in-out, ease-out, ease-in;
    transition-duration: 200ms;
    transition-delay: 200ms, 200ms, 0;

    &:before {
      content: '';
      @apply block h-0.5 w-full;
      background-color: currentColor;
      left: 50%;
      top: 50%;
      transform: rotate(0deg);
      transition: transform 200ms ease-out 200ms;
    }
  }
  input:checked ~ & {
    div {
      box-shadow: none;
      transform: rotate(45deg);

      &:before {
        transform: rotate(90deg);
      }
    }
  }
}

.nav-link {
  font-family: $font-display;
  @apply my-1 block text-base font-medium whitespace-nowrap;

  @each $link in $navlinks {
    &:hover,
    body.#{$link} &--#{$link} {
      @apply text-red;
    }
  }
  &.nav-link.nav-link--solutions {
    @media screen and (max-width: 639px) {
      color: theme('colors.gray.400') !important;
    }
  }

  &-dropdown-container {
    @screen md {
      position: relative;
      &:hover .nav-link-dropdown {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &-dropdown {
    @apply space-y-6 pl-8;
    @screen md {
      transition: all ease-in-out 150ms;
      opacity: 0;
      visibility: hidden;
      @apply bg-white shadow-lg p-6 md:space-y-2 absolute rounded left-[50%] translate-x-[-50%];
      top: calc(100% + 0.5rem);
      box-shadow: 0 0 6px rgba(black, 0.4);
    }
  }
}
