.hero-panel {
  .container {
    z-index: 5;
  }
  &-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      object-fit: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;
      @apply bg-black;
  }

  &--home {
    &::before {
      z-index: 2;
    }
    &:after {
      @apply bg-black;
      z-index: 0;
    }
  }
}

.dataloop {
  &-panel {
    background: transparent;
    @apply z-10;
    height: 70vh;
    max-height: 70vh;
    @screen md {
      height: 100vh;
      max-height: 550px;
    }
  }
  &__container {
  }
  &__sticky {
    @apply fixed dead-center; // h-[70vh] top-[15vh] flex items-center justify-center;
  }
  &__headline {
    span {
      @apply inline xl:block;
    }
    &:after {
      @apply absolute block dead-center rounded-full h-1/2 w-1/2;
      content: '';
      z-index: -1;
      filter: blur(50px);
      background-color: rgba(0, 0, 0, 0.6);
      mix-blend-mode: multiply;
    }
  }

  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    @apply bg-black;
    // display: none;
    &.active {
      display: block;
    }
    &-video {
      object-fit: cover;
      @apply w-full h-full;
    }
    &:before {
      content: '';
      @apply block absolute inset-0 z-[1];
      @include tvscreen;
    }
  }
}

.diagram {

  position: relative;
  min-height: 250px;
  max-height: 250px;

  @screen lg {
    min-height: 450px;
    max-height: 450px;
    min-width: 600px;
  }


  &-sticky {
    @apply absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(1.2);
    width: 120%;
    @screen lg {
      left: auto;
      width: auto;
      min-width: 600px;
      transform: translate(-10rem, -50%) scale(1.2);
    }
    @screen xl {
      transform: translate(-10rem, -50%) scale(1.5);
    }
  }
}

#cpg-minidiagram {
  width: 100%;

  @screen md {
    max-width: 550px;
    @apply mx-auto;
  }
  @screen lg {
    width: calc(100% + #{theme('spacing.40')});
    max-width: initial;
  }
  #lines path {
    animation: dancing-ants 1.5s infinite linear;
  }
}

@keyframes dancing-ants {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 20;
  }
}

$decorativeLineWidth: 200vw;
$decorativeLineHeight: 100vh;
.overview {
  &-panel {
    @apply p-0 relative lg:text-lg leading-relaxed;
    > * {
      z-index: 5;
    }
    & + .panel {
      @apply relative overflow-hidden pt-[20rem];
      &:before {
        @apply absolute top-0 bg-black;
        // content: '';
        width: $decorativeLineWidth;
        height: $decorativeLineHeight;
        z-index: 10;
        border-radius: 50%;
        transform: translate(-1%, -84%);
      }
    }
  }

  &-panel-grid {
    @apply grid grid-cols-1 gap-y-12;
    @apply pt-12 pb-16 lg:py-32 relative;

    [class*='overview-content'] {
      @apply px-8 space-y-8;
    }
    @screen lg {
      @apply grid-cols-2 gap-12 gap-y-24 static items-center;
    }
  }
  &-diagram {
    @apply mt-20 mb-24;
  }

  &-band {

    @screen lg {
      min-height: 240px;
    }

    .container {
      @apply px-20 py-10 w-screen;
      @screen lg {
        @apply p-20 absolute left-0 right-0;
        max-width: 100vw;
      }
      @apply text-center font-light text-blue bg-gray-200 shadow-lg drop-shadow-lg;
      background-image: linear-gradient(
          90deg,
          theme('colors.black') -40%,
          transparent 50%,
          theme('colors.black') 140%
        ),
        linear-gradient(
          0deg,
          theme('colors.gray.200') 0%,
          #2d3a4d 50%,
          theme('colors.gray.200') 100%
        );
      h3 {
        text-shadow: 0 0 1px rgba(black, 1), 0 0 24px rgba(#fff, 0.38);
      }
    }
  }
}
.clipping-container {
  overflow: hidden;
  max-width: 100vw;

  &--for-decor-lines {
    z-index: -1;
    $offset: 150px;
    @apply absolute;
    bottom: $offset * -1;
    height: calc(#{$decorativeLineHeight} + #{$offset});
  }
}
.decorative-lines {
  transform: scale(1.12) translate(-18%, 0);
  width: $decorativeLineWidth;
  min-width: 1000px;
  height: $decorativeLineHeight;
  border-radius: 50%;
  @apply border-b bg-black;
  border-color: rgba(white, 0.3);
  z-index: -1 !important;
  box-shadow: 0 0 0 40px theme('colors.black');
  &:before,
  &:after {
    content: '';
    @apply absolute w-full h-full block;
    border-radius: 50%;
    @apply border-b;
    border-color: rgba(white, 0.3);
    transform-origin: left center;
  }
  &:before {
    transform: scale(0.92592593) translate(-1%, 0);
    z-index: -2;
  }
  &:after {
    transform: scale(0.89285714) translate(-1.5%, 0);
    z-index: -3;
    @apply bg-black;
  }
}

.cta-panel.panel {
  position: relative;
  z-index: 19;
  background-image: url('assets/images/contact-lines-full.png');
  @apply bg-no-repeat pt-56 md:pt-64 -mt-32 pb-64 md:pb-24;
  @apply box-content min-h-[380px] md:min-h-[420px]; // h-[50vh]
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  transform: translate3d(0, 0, 0);
  @screen lg {
    @apply -mt-16;
    background-size: 140% auto;
  }
}

.latest-news-panel {
  z-index: 20;
  position: relative;
  @apply -mb-32 p-0;
  .container {
    @apply md:max-w-5xl px-10;
  }
  .swiper-slide {
    height: auto;
  }
}
.news-block {
  @apply bg-white rounded p-6 flow-root h-full min-h-[240px];
  @screen md {
    @apply flex-row p-12;
  }
  &__body {
    @apply md:flex md:flex-row md:flex-wrap justify-between h-full;
  }

  &__title {
    @apply text-xl md:text-2xl mb-6 md:w-3/4 md:pr-8;
  }
  &__excerpt {
    @apply antialiased max-w-prose mb-8;
  }
  &__image {
    float: right;
    // margin-left: auto;
    @apply w-32;
    @apply float-right pt-0;
    @apply md:w-44 flex-shrink-0 md:pt-1 md:float-none grayscale mb-6;
  }
  &__cta {
    flex-basis: 100%;
    margin-top: auto;
  }
}

ul.a-typical-bulleted-list {
  // list-style-type: disc;
  li {
    position: relative;
    padding-left: 15px;
  }
  li:before {
    content: '–';
    @apply inline absolute left-0;
  }
}
