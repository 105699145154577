.leader {
  @apply pl-4 border-l-4 border-gray-400 mb-12  relative;

  &__name {
    @apply font-display font-medium text-xl mb-0;
  }
  &__title {
    @apply leadin-label mb-2;
  }
  &__linkedin {
    @apply inline-block text-gray-400 transition-colors ml-2;
    vertical-align: 2px;

    &:hover {
      @apply text-red;
    }
  }
  &__cv {
    @apply leading-relaxed text-sm;
    strong {
      @apply font-bold whitespace-nowrap italic;
    }
    em {
      @apply text-gray-400 text-sm;
    }
  }

  .leaders-vp & {
    @apply border-gray-400;
  }
}

.leaders-list {
  @apply md:mb-32;
}
