@layer components {
  .panel {
    @apply px-8 py-16 pb-20 bg-white relative z-20;
    &.--add-black-fade {
      &:before {
        @apply block absolute inset-x-0 bottom-[100%] h-32;
        content: '';
        background-image: linear-gradient(0deg, theme('colors.black') 0%, rgba(0,0,0,0) 100%);
      }
    }
    &.--add-decor-awning {
      overflow: visible;
      z-index: 30;
      &::after {
        content: '';
        @apply absolute z-50 inset-x-0 top-[100%] h-10 bg-black block;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
      }
    }
    @screen md {
      @apply pt-28 pb-32;
    }
    &--tall {
      @apply pt-64 pb-48;
    }
    &--light-gray {
      @apply bg-light-gray;
    }
    &--dark {
      @apply text-white bg-gray-200;
    }
    &--black {
      @apply text-white bg-black;
    }
    &--black-radial {
        @apply text-white bg-black;
        background-image: radial-gradient(ellipse, theme('colors.gray.200') 0%, theme('colors.black') 90%);
    }
    &--clear {
      background-color: transparent;
    }
    &--split {
      @apply py-0;
      background-color: transparent;
      background-image: linear-gradient(
        0deg,
        transparent 0%,
        theme('colors.light-gray') 30%,
        theme('colors.light-gray') 50%,
        theme('colors.black') 50%
      );
    }
    &--white-gray-wash {
      background-image: linear-gradient(
        180deg,
        white 0%,
        theme('colors.light-gray') 100%
      );
    }
    &--splitrev {
      @apply py-0;
      background-color: transparent;
      background-image: linear-gradient(
        180deg,
        transparent 0%,
        theme('colors.light-gray') 30%,
        transparent 50%,
        theme('colors.gray.200') 50%
      );
    }
    &--waves {
      @apply bg-waves;
      box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.25);
    }

    &.combine-adjacent {
      @apply pb-8 lg:pb-16;
      & + .panel {
        @apply pt-8 lg:pt-16;
      }
    }
    &.combine-adjacent-tight {
      @apply pb-4 lg:pb-8;
      & + .panel {
        @apply pt-4 lg:pt-8;
      }
    }
    &.collapse-adjacent {
      @apply py-0;
      & + .panel {
        @apply pt-1;
      }
    }

  }

  .hero-panel {
    height: 100vh;
    max-height: calc(100vh - 120px);
    @apply bg-black text-white flex items-center md:min-h-[800px] relative pt-32 md:pt-[13rem];

    .container {
      position: relative;
      z-index: 2;
    }

    &:after {
      @apply bg-no-repeat bg-cover inset-0 z-0 block absolute bg-center;
      content: '';
    }
    &:before {
      content: '';
      @apply inset-0 absolute block z-[1];
      @include tvscreen;
    }

    &--shorter {
      min-height: 300px;
      max-height: 300px;
      @apply grid place-items-center pt-[70px] pb-0;
      @screen md {
        min-height: 475px;
        max-height: 475px;
      }
    }
    &--short {
      min-height: 475px;
      max-height: 475px;
      @screen md {
        min-height: 475px;
        max-height: 475px;
      }
    }
    &--white {
      @apply bg-white text-black;
      &:before {
        display: none;
      }
    }
    &--home:after {
    }
    &--distributors:after {
      background-image: url('assets/images/hero-distributors.jpg');
    }
    &--brands:after {
      background-image: url('assets/images/hero-brands.jpg');
      // background-position: top center;
    }
    &--blog:after {
      background-image: url('assets/images/hero-blog.webp');
      // background-position: top center;
    }
    &--retailers:after {
      background-image: url('assets/images/hero-retailers.jpg');
    }
    &--about {
      max-height: 70vh;
      @screen lg {
        max-height: none;
      }
      &:after {
        background-image: url('assets/images/hero-about.jpg');
        background-position: top center;
      }
    }
    &--contact {
      height: auto;
      max-height: none;
      // padding-top: 6.5rem;

      &:before {
        // display: none;
        z-index: 30;
        background: transparent;
        pointer-events: none;
        box-shadow: inset 0 -14px 25px -15px rgba(black, 0.2);
      }
      &:after {
        background-image: url('assets/images/hero-contact.jpg');
      }
    }
  }
}
