@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .row {
    @apply flex justify-between items-center;
  }

  .h-center {
    left: 50%;
    transform: translateX(-50%);
  }
  .v-center {
    top: 50%;
    transform: translateY(-50%);
  }
  .dead-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-shadow {
    text-shadow: 0 1px 2px rgba(black, 0.4);
  }
  .bg-waves {
    background-image: url('assets/images/blue-waves.png'),
      linear-gradient(
        0deg,
        theme('colors.blue.dark') 0%,
        theme('colors.blue.light') 100%
      );
    background-size: cover;
    background-position: center;
  }
}
