@import 'core/tailwind';
@import 'core/constants';
@import 'core/cssvar';

@layer base {
  @import 'core/base';
  @import 'core/typography';
}

@layer components {
  @import 'core/header';
  @import 'core/footer';

  @import 'components/audience-blocks';
  @import 'core/panels';
}
@import 'components/benefits';
@import 'core/forms';
@import 'components/contact-locations';
@import 'pages/home';
@import 'pages/about';
@import 'pages/platform';
@import 'components/leaders';
@import 'components/clients-panel';
@import '../../../node_modules/swiper/swiper-bundle.css';

.container {
    // @apply max-w-5xl m-auto;

    @screen 2xl {
        @apply max-w-7xl;
    }

}
