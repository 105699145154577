input,
select,
textarea {
  @apply border border-gray-500 rounded text-gray px-3 py-2;
}
form {
  margin: 0;
}
.form-container {
  @apply relative;
  @apply transition-all duration-300;
}
.form-status {
  overflow: hidden;
  max-height: 500px;
  width: 75%;
  @apply absolute transition-all duration-300;
  @apply -mt-8 p-12 text-center rounded bg-white;
}
.form-error {
  @apply row text-red;
}
.form-success {
  @apply bg-white text-blue p-12;
}
.contact-form {
  @apply p-8 md:p-10 lg:rounded  shadow-2xl bg-white-fade h-full;
  @apply text-gray-200;

  .long-contact {
    @apply transition-opacity duration-300;
  }

  .hero-panel & {
    @apply rounded-b-none;
  }
}
