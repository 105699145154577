.audience-blocks {
  @apply grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 w-full;
}

.audience-block {
  @apply rounded shadow-md bg-white text-gray block transition-all overflow-hidden;

  &:hover {
    @apply shadow-lg  text-blue;
  }
  &__img {
    overflow: hidden;
    @apply h-32 lg:h-auto;

    &.distributors {
      img {
        @apply relative -top-16 md:top-0;
      }
    }
  }
  &__footer {
    @apply row px-6 py-3 lg:p-6;
  }
  &__label {
    font-family: $font-display;
    @apply font-medium text-lg lg:text-xl;
  }
}
