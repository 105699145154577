.client-logos {
  @apply text-gray;

  .fullbreak {
    height: 0;
    @screen xl {
      flex: 1 0 100%;
    }
  }
}
.client-logo {
  &-svg__img {
    @apply text-gray;
    @screen lg {
      max-width: none !important;
    }
  }
}
.logowall {
  .client-logos {
    // @apply justify-center;
  }
  .fullbreak {
    display:none;
  }
}