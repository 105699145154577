.panel--design-principles {
  @apply pt-32;
  background-image: url('assets/images/hex-tile.svg');
  background-repeat: no-repeat;
  background-position: right 0 top 350px;
  background-size: 120% auto;
  @screen lg {
    background-position: right center;
    background-size: 75% auto;

  }
}

.card {
  &__media {
    @apply rounded bg-gray-200 mb-6 flex items-center justify-center h-0 pb-[75%] relative;
    picture {
      @apply absolute dead-center;
    }
  }
  &__title {
    @apply text-lg font-display font-medium mb-4;
  }
  &__message {
    @apply text-sm text-gray-500 antialiased;
  }
}
.ai-core {
  &-panel {
    @apply relative lg:pt-40 lg:pb-56;
  }
  &-illustration {
    @apply -my-12;
    @screen lg {
      width: 1190px;
      height: 1002px;
      @apply absolute left-[50%] top-[50%];
      transform: translate3d(-73.25%, -45%, 0);

    }
  }
}
.ai-core-points {
  @apply space-y-6 ;
  @screen lg {

    padding-left: 55%;
    @apply mt-24 space-y-12 ;
  }


  p {
    @apply py-4 px-5 text-lg max-w-[430px] antialiased mx-auto;
    background-image: linear-gradient(180deg, rgba(black, .75) 0%, rgba(black, 0) 90%);
    @screen lg {
      background-image: linear-gradient(90deg, rgba(black, .75) 0%, rgba(black, 0) 90%);
    }
  }
}

.points-carousel {
  @apply lg:flex;

  &__nav {
    @screen lg {
      width: 260px;
    }
    .slidenav {
      @apply flex gap-2 flex-wrap;
      @screen lg {

        @apply space-y-2 flex flex-col items-start;
      }
    }
    .slidenav-item {
      @apply text-xl lg:text-2xl text-white bg-gray-400 py-1 px-3 whitespace-nowrap transition-colors;
      &:hover {
        cursor: pointer;
        @apply bg-gray;
      }
      &.is-active {
        @apply bg-red;
      }
    }
  }

  &__body {
    width: calc(100% + 64px);
    @apply mt-6 -ml-8 overflow-hidden md:overflow-visible;
    @screen lg {
      width: calc(100% - 260px);
    }
    position: relative;
    &:before {
      content: '';
      @apply block absolute left-0 inset-y-0 w-12 z-50 ;
      background-image: linear-gradient(90deg, theme('colors.light-gray') 0%, transparent 100%);
    }
    &:after {
      content: '';
      @apply block absolute -right-12 inset-y-0 w-24 z-50  opacity-0 duration-300 transition-opacity;
      background-image: linear-gradient(-90deg, transparent 0%, theme('colors.light-gray') 50%, transparent 100%);
    }
    &.transitioning:after {
      @apply opacity-100 ;
    }
    .swiper {
      @apply pb-4;
    }
    .swiper-wrapper {
      @apply items-stretch;
      height: 100%;
    }
    .swiper-slide {
      min-height: 100%;
      @apply px-8;
    }
    .slide {
      @apply bg-white shadow-lg  p-12 rounded;
    }
  }
}

.data-integrations {
  @apply relative grid grid-cols-3 gap-6;
  &:after {
    content: '';
    @apply inset-x-0 bottom-[-25%] top-[30%] block z-50 absolute;
    background-image: linear-gradient(0deg, white 3%, rgba(white, 0) 100%);
  }

  &-block {
    @apply bg-light-gray px-6 pb-[100%] h-0 rounded-lg shadow-lg relative;

    &:nth-child(3n-1) {
      transform: translateY(45%);
    }
    &:nth-child(3n-2) {
      transform: translateY(25%);
    }
  }
  &-logo {
    @apply dead-center absolute ;

    img {
      max-height: 56px;
    }
  }
}