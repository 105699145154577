.about-header-img {
  @apply absolute h-screen w-screen overflow-hidden -mx-8 top-16 md:top-0;
  picture {
    @screen md {
      @apply absolute top-16 md:top-0  w-[60vw] left-0 -ml-6 min-h-[638px] z-[-1];
    }
    img {
      @apply absolute h-center;
      transform: translateX(-57%);
      width: 120%;
      max-width: 120%;
      @screen md {
        width: auto;
        object-fit: cover;
        min-height: 684px;
      }
    }
  }
}
.about-block {
  @apply mx-auto p-6 lg:p-12 bg-white;
  @screen md {
    // @apply col-start-7 col-span-6 p-20 -mt-20 -ml-20;
  }
}

.about-intro-panel {
  @apply pt-0;

  .container {
    @apply pt-48 md:pt-96 md:mt-64 mt-14;
  }
}

.nag-cta {
  @apply relative m-auto;
  @apply p-8  bg-white flex flex-col   rounded shadow-lg;
  @screen md {
    @apply flex flex-row items-center p-12;
    // transform: translateX(-10%);

    &:before {
      @apply absolute -left-8 -top-8 w-1/6 block h-1/2 border-red border-t-4 border-l-4;
      // content: '';
    }
    &:after {
      @apply absolute -right-8 -bottom-8 w-1/6 block h-1/2 border-red border-b-4 border-r-4;
      content: '';
    }
  }
}
.add-corner-frame-bottom {
  @apply relative pr-6 pb-6;
  &:after {
    @apply absolute -right-0 -bottom-0 min-w-[8rem] w-1/6 block h-1/2 max-h-[8rem] border-red border-b-4 border-r-4;
    content: '';
  }
}

.leadership-panel {
  position: relative;
  padding-top: 0;

  .leader__image {
    margin-bottom: 10px;

    img {
      max-width: 225px;
      height: auto;
    }
  }

  .tickr-phone-ui {
    @apply max-w-[450px]  block;
    margin-bottom: -50px;
  }
  .tickr-phone-ui-mobile {
    width: 90%;
    @apply mx-auto mt-16 md:hidden;
  }
  .tickr-phone-ui-desktop {
    @apply hidden md:block h-center absolute;
    @apply left-auto right-1/2;
    transform: translateX(-25%);
  }
}
