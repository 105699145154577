.contact-locations {
  @apply grid grid-cols-1 gap-16 relative;
  @screen md {
    @apply grid-cols-3 gap-16;
  }
}

.contact-location {
  @apply text-center;
  &__image {
    @apply block mb-12 relative;
    img {
      filter: grayscale(1);
    }
  }

  &:first-child &__image:before {
    @apply absolute -left-8 -top-8 w-1/6 block h-1/2 border-red border-t-4 border-l-4;
    content: "";
  }
  &:last-child &__image:before {
    @apply absolute -right-8 -bottom-8 w-1/6 block h-1/2 border-blue border-b-4 border-r-4;
    content: "";
  }

  &__title {
    @apply font-display text-2xl font-medium mb-4;
  }
  &__address {
    @apply font-display text-lg text-gray-400 transition-colors;
    &:hover {
      @apply text-black;
    }
  }
  &__phone {
    @apply mt-4 font-display text-lg text-gray-400;
  }
}
