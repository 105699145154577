.benefits-grid {
  @apply space-y-12 mt-12;
  @screen md {
    @apply grid grid-cols-4;
    @apply gap-y-16 gap-x-32 space-y-0;
  }
}
.benefit-point {
  @apply col-span-1;
  @screen md {
    @apply flex flex-row space-x-8 col-span-2;
  }

  &__graphic {
    @apply block mb-2;
    max-width: 50px;
    @screen md {
      @apply w-[50px];
      flex: 0 0 50px;
    }
  }
  &__message {
    @apply col-start-2 col-span-4;

    p {
      @apply text-base text-gray;
    }
  }
  &__headline {
    @apply text-xl mb-2;
  }

  &:last-child:nth-child(2n - 1) {
    @apply col-span-4;
  }
}
